:root {
  /* COLOR */
  --accent-primary: #0064ff;
  --accent-secondary: #b100e8;
  --object-primary: #00143c;
  --object-secondary: #80899d;
  --placeholder: #b9bec8;
  --border: #d9dfe7;
  --background: #eceff4;
  --highlight: #f6f8f9;
  --base: #fff;
  --success: #1bb560;
  --warning: #e59f02;
  --error: #cf3a1f;
  --dark-bg: #ffffff;

  --font-xxxxs: 10px;
  --font-xxxs: 11px;
  --font-xxs: 12px;
  --font-xs: 13px;
  --font-sm: 14px;
  --font-md: 16px;
  --font-lg: 17px;
  --font-xl: 18px;
  --font-xxl: 20px;
  --font-xxxl: 22px;
  --font-xxxxl: 26px;
  --font-xxxxxl: 27px;
  --font-xxxxxxl: 38px;

  /* --font-xxxxs: 8px;
  --font-xxxs: 9px;
  --font-xxs: 10px;
  --font-xs: 11px;
  --font-sm: 12px;
  --font-md: 14px;
  --font-lg: 15px;
  --font-xl: 16px;
  --font-xxl: 18px;
  --font-xxxl: 20px;
  --font-xxxxl: 24px;
  --font-xxxxxl: 25px;
  --font-xxxxxxl: 36px; */

  /* FONTS */
  --font-main: Lato, sans-serif;
  --font-monospaced: Roboto Mono, Consolas, Menlo, Monaco, Lucida Console,
    Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New,
    monospace;
}

.themetoggle button.sun {
  color: var(--warning);
}
.themetoggle button.moon {
  color: var(--placeholder);
}

.svg-logo-1 {
  fill: var(--object-primary);
}
.svg-logo-2 {
  fill: var(--accent-secondary);
}

/* DARK THEME */
body.theme-dark {
  --accent-primary: #3282ff;
  --accent-secondary: #c91cff;
  --object-primary: #ffffff;
  --object-secondary: #99a1b1;
  --placeholder: #475673;
  --border: #293a5b;
  --background: #00143c;
  --highlight: #14274c;
  --base: #0a1d44;
  --success: #1ec96b;
  --warning: #ffb102;
  --error: #e64022;
  --dark-bg: #ffffff;
}

body.theme-dark .themetoggle button.sun {
  color: var(--placeholder);
}
body.theme-dark .themetoggle button.moon {
  color: var(--warning);
}
